<template>
  <div class="grid">

    <div class="col-12">
      <div class="card">
        <h5>Produtos Compatíveis</h5>

        <div v-if="exibTentarNovamente">
          <h6>Tempo de espera maior que esperado na resposta da cotação</h6>
          <Button icon="pi pi-sync" label="Tentar novamente" class="mb-2"
                  @click="getGeradoresFS()">
          </Button>
        </div>

        <div  class="custom-skeleton p-4" v-if="loadingCotacoes === true && !exibTentarNovamente">
          <Skeleton width="100%" height="50px"></Skeleton>
          <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
          <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
          <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
          <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
          <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
          <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
          <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
          <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
        </div>


        <DataView :value="dataviewValue" :layout="layout" :paginator="false" :rows="40" :sortOrder="sortOrder" :sortField="sortField" v-if="loadingCotacoes === false && !exibTentarNovamente">
          <template #header>
            <div class="grid grid-nogutter">
              <div class="col-6 text-left">
                <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label" placeholder="Ordenar pelo preço" @change="onSortChange($event)"/>
              </div>
<!--              <div class="col-6 text-right">-->
<!--                <DataViewLayoutOptions v-model="layout" />-->
<!--              </div>-->
            </div>
          </template>
          <template #list="slotProps">
            <div class="col-12">

                <div class="flex flex-column md:flex-row align-items-center p-3 w-full">

                  <img :src="slotProps.data.img1" :alt="slotProps.data.descricao" class="my-4 md:my-0 w-9 md:w-6rem mr-0 md:mr-5" />
                  <img :src="slotProps.data.img2" :alt="slotProps.data.descricao" class="my-4 md:my-0 w-9 md:w-6rem mr-0 md:mr-5" />

                  <div class="flex-1 text-center md:text-left">
                    <div class="font-bold text-2xl">{{slotProps.data.descricao}}</div>
                    <div class="mb-3">{{slotProps.data.preco}}</div>
                    <Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false" class="mb-2"></Rating>
                    <div class="flex align-items-center">
<!--                      <a :href="slotProps.data.link">-->
                        <i class="pi pi-tag mr-2"></i>
                        <span class="font-semibold">Melhor opção (Indicador 10,00)</span>
<!--                      </a>-->
                    </div>

                  </div>
                  <div class="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0">
                    <span class="text-2xl font-semibold mb-2 align-self-center md:align-self-end">{{ formatCurrency(slotProps.data.price)}}</span>
                    <Button icon="pi pi-shopping-cart" label="Escolher" :disabled="slotProps.data.inventoryStatus === 'OUTOFSTOCK'" class="mb-2"
                            @click="escolherCotacao(slotProps.data)">

                    </Button>
                    <span :class="'product-badge status-no estoque'">{{slotProps.data.reaiskwp}}</span>
                  </div>
                </div>

            </div>
          </template>

        </DataView>
      </div>
    </div>




  </div>
</template>

<script>
import OrcamentoService from "../../service/OrcamentoService";

export default {
  emits: ['menubutton-click', 'topbar-menu-click', 'topbar-item-click', 'topbar-submenu-click', 'search-item-click'],
  props: {
    orcamentoData: {
      type: Object
    },
  },
  data() {
    return {
      exibTentarNovamente: false,
      loadingCotacoes: true,
      picklistValue: [[
        {name: 'San Francisco', code: 'SF'},
        {name: 'London', code: 'LDN'},
        {name: 'Paris', code: 'PRS'},
        {name: 'Istanbul', code: 'IST'},
        {name: 'Berlin', code: 'BRL'},
        {name: 'Barcelona', code: 'BRC'},
        {name: 'Rome', code: 'RM'},
      ],[]],
      orderlistValue: [
        {name: 'San Francisco', code: 'SF'},
        {name: 'London', code: 'LDN'},
        {name: 'Paris', code: 'PRS'},
        {name: 'Istanbul', code: 'IST'},
        {name: 'Berlin', code: 'BRL'},
        {name: 'Barcelona', code: 'BRC'},
        {name: 'Rome', code: 'RM'},
      ],
      dataviewValue: null,
      layout: 'list',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        {label: 'Preço maior para menor', value: '!preco'},
        {label: 'Preço menor para maior', value: 'preco'},
      ]
    }
  },
  orcamentoService: null,
  created() {
    this.orcamentoService = new OrcamentoService();
  },
  mounted() {
    //this.orcamentoService.getCotacoes().then(data => this.dataviewValue = data);
    this.getGeradoresFS()


  },
  methods: {
    getGeradoresFS(){
      this.exibTentarNovamente = false
      this.orcamentoService.getGeradoresFS({
        potencia: this.orcamentoData.potencia.toString(),
        modulo: this.orcamentoData.modulo,
        tensao: this.orcamentoData.tensao,
        estrutura: this.orcamentoData.estrutura
      }).then((data) => {
        console.log('getGeradoresFS')
        if (data[0] === 'excecao') {
          setTimeout(() => {
            console.log("excecao python!")
            this.exibTentarNovamente = true
          }, 5000);

        }else{
          console.log(data[0])
          this.dataviewValue = data
          this.loadingCotacoes = false
        }

      });
    },

    escolherCotacao(produto){
      console.log('produto_0')
      console.log(produto)
      this.loadingCotacoes = true

      this.orcamentoService.getEscolheGerador({
        potencia: this.orcamentoData.potencia.toString(),
        modulo: this.orcamentoData.modulo,
        tensao: this.orcamentoData.tensao,
        estrutura: this.orcamentoData.estrutura,
        valor: produto.preco,
        f1: produto.img1,
        f2: produto.img2,
        orctoid: this.orcamentoData.id.toString()
      }).then((dataCotacao) => {
        console.log('getEscolheGerador')
        console.log(dataCotacao)
        this.orcamentoService.savecotacao({dataCotacao}).then((dataSaveCota) => {
          console.log('savecotacao')
          console.log(dataSaveCota)

          this.$emit('escolheOutraCotacao', dataSaveCota);
          this.$emit('hideCotacao');

          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Cotação registrada com sucesso', life: 3000});
          this.loadingCotacoes = false
        });
      });
    },

    onSortChange(event){
      const value = event.value.value;
      const sortValue = event.value;

      if (value.indexOf('!') === 0) {
        this.sortOrder = -1;
        this.sortField = value.substring(1, value.length);
        this.sortKey = sortValue;
      }
      else {
        this.sortOrder = 1;
        this.sortField = value;
        this.sortKey = sortValue;
      }
    },

    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },
    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
